<template>
  <div>
    <h1 class="text-size-h1 text-bold mb-m">
      {{ $metaInfo.title }}
    </h1>
    <div
      v-if="!isJunior"
      class="row mb-l">
      <div class="col-md-6 col-sm-6 col-xs-12 color-meta text-size-s">
        <p>
          Выбери {{ profileLimit }}, в&nbsp;зависимости от&nbsp;них будет
          сформирован список предметов и&nbsp;заданий олимпиады.
        </p>
      </div>
    </div>
    <div v-if="pending">
      <BaseSpinner brand />
    </div>
    <div v-else>
      <!-- Фильтры -->
      <div
        v-if="!isJunior && profilesList && profilesList.length"
        class="panel radius-m mb-l">
        <div class="row">
          <div class="col-sm-6 col-xs-12 mb-m">
            <label
              class="form-label text-bold text-size-s"
              for="profile_search"
              >Искать по названию</label
            >
            <BaseInput
              id="profile_search"
              v-model.trim="search"
              name="profile_search" />
          </div>

          <div class="col-sm-6 col-xs-12">
            <label class="form-label text-bold text-size-s"
              >Искать по компетенциям</label
            >
            <BaseSelect
              v-model="selectedRoutes"
              :options="routesOptions"
              label="title"
              track-by="id"
              placeholder="Выберите компетенции"
              :multiple="true"
              :close-on-select="false">
              <template #selection="{ values, isOpen }">
                <div v-if="values.length === 1 && !isOpen">
                  {{ values[0].title }}
                </div>
                <div v-else-if="values.length && !isOpen">
                  Выбрано {{ values.length }}
                </div>
              </template>

              <template #noResult>Такой компетенции нет в списке</template>
              <template #noOptions>Список пуст</template>
            </BaseSelect>
            <div class="select-reset text-size-s">
              <a
                v-if="selectedRoutes.length"
                href="#"
                class="link link--pseudo"
                @click.prevent="selectedRoutes = []"
                >Очистить поле</a
              >
              &nbsp;
            </div>
          </div>
        </div>

        <div class="mb-m">
          <label class="form-label text-bold text-size-s"
            >Искать по предметам</label
          >
          <BaseSelect
            v-model="selectedActivities"
            :options="activitiesOptions"
            label="title"
            track-by="id"
            placeholder="Выберите предмет"
            :multiple="true"
            :close-on-select="false">
            <template #selection="{ values, isOpen }">
              <div v-if="values.length === 1 && !isOpen">
                {{ values[0].title }}
              </div>
              <div v-else-if="values.length && !isOpen">
                Выбрано {{ values.length }}
              </div>
            </template>

            <template #noResult>Такого предмета нет в списке</template>
            <template #noOptions>Список пуст</template>
          </BaseSelect>
          <div class="select-reset text-size-s">
            <a
              v-if="selectedActivities.length"
              href="#"
              class="link link--pseudo"
              @click.prevent="selectedActivities = []"
              >Очистить поле</a
            >
            &nbsp;
          </div>
        </div>
      </div>
      <div class="row cards-list">
        <div
          v-for="profile in filteredList"
          :key="profile.id"
          class="col-sm-6 col-xs-12 cards-list__item">
          <ProfileCard
            :id="profile.id"
            class="cards-list__card"
            :icon="profile.icon"
            :title="profile.title"
            :link-to="{
              name: 'user-profile-stage-1',
              params: {
                profile_id: profile.id,
              },
            }"
            :label="labels[profile.profileType].name"
            :tags="profile.routes"
            :rsosh-level="profile.rsosh_level"
            :detail-url="profile.detail_url">
            <p v-if="profile.short_description">
              {{ profile.short_description }}
            </p>
            <!-- <div
              v-if="
                profile.finalEvent && profile.finalEvent.format === 'offline'
              "
            >
              Регион финала: {{ profile.finalEvent.region_with_type }}
            </div> -->

            <template #footer>
              <BaseButton
                v-if="selectedProfiles[profile.id]"
                :id="`deslect-profile-${profile.id}`"
                class="select-profile-btn is-active"
                block
                :title="`Отменить выбор профиля: ${profile.title}`"
                :disabled="isProfileSelectPending"
                @click.prevent="handleRefuseProfile(profile)"
                ><span class="select-profile-btn__check"></span
                >{{ labels[profile.profileType].selected }}</BaseButton
              >
              <BaseButton
                v-else
                :id="`select-profile-${profile.id}`"
                class="select-profile-btn"
                theme="primary-border"
                :title="`Выбрать ${profile.title}`"
                block
                :disabled="isProfileSelectPending"
                @click.prevent="confirmPolicyAndSelect(profile)"
                ><span class="select-profile-btn__check"></span
                >{{ labels[profile.profileType].select }}</BaseButton
              >
            </template>
          </ProfileCard>
        </div>
      </div>

      <div v-if="hasFilters && !filteredList.length">
        <p>
          По заданным параметрам, ничего не найдено.
          <a
            href="#"
            class="link link--pseudo"
            @click.prevent="dropFilters"
            >Сбросить фильтры</a
          >
        </p>
      </div>
      <div v-else-if="!filteredList.length">
        <p>
          Список профилей пуст. Мы уточняем программу олимпиады. Профили
          появятся позже.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import shuffle from "lodash/shuffle";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import { mapGetters } from "vuex";
import ProfileCard from "@/components/profile/ProfileCard";
import { profileGrouper } from "@/utils/profile";
import { numCases } from "@/utils";
import ProfieTrackSelectModal from "@/components/user/modals/ProfieTrackSelectModal";
import ProfilePolicyModal from "@/components/user/modals/ProfilePolicyModal";
import { FINAL_STAGE, MODAL_DYNAMIC_DEFAULTS } from "@/constants";
import { getJuniorFinalEvent } from "@/utils/participant";
import { ScheduleService } from "@/services/scheduleService";

export default {
  name: "UserProfiles",
  components: { ProfileCard },
  metaInfo() {
    return {
      title: this.isJunior ? "Все сферы НТО Junior" : "Профили НТО",
    };
  },
  data() {
    return {
      pending: true,
      pendingProfiles: {},
      initialSelectedProfiles: {},
      search: "",
      selectedRoutes: [],
      selectedActivities: [],
    };
  },
  computed: {
    ...mapGetters({
      list: "profile/profiles",
      specials: "profile/specials",
      allProfiles: "profile/allProfiles",
      selectedList: "participant/selectedList",
      limit: "participant/profileLimit",
      isJunior: "participant/isJunior",
      is4GradeJunior: "participant/is4GradeJunior",
      isStudent: "participant/isStudent",
      labels: "participant/profileLabels",
      currentEducation: "participant/currentEducation",
    }),
    // eslint-disable-next-line
    achievementsByRoutes() {
      const achievements = this.$store.state.participant.achievements;
      if (!achievements || !achievements.length) return {};
      return achievements.reduce((acc, achievement) => {
        if (achievement.event_routes?.length) {
          achievement.event_routes.forEach((routeId) => {
            if (acc[routeId]) {
              acc[routeId] += 1;
            } else {
              acc[routeId] = 1;
            }
          });
        }
        return acc;
      }, {});
    },
    events() {
      return this.$store.state.profile.events;
    },
    selectedProfiles() {
      return this.selectedList.reduce((acc, value) => {
        acc[value.profile_id] = true;
        return acc;
      }, {});
    },
    profilesList() {
      if (this.pending) return [];
      const { list, specials, initialSelectedProfiles, achievementsByRoutes } =
        this;
      const groupedProfiles = profileGrouper(
        list,
        initialSelectedProfiles,
        achievementsByRoutes,
        "profile"
      );
      const groupedSpecials = profileGrouper(
        specials,
        initialSelectedProfiles,
        achievementsByRoutes,
        "special"
      );

      let result = [
        ...groupedProfiles[0], // выбранные профили
        ...groupedSpecials[0], // выбранные спец проекты
        ...orderBy(groupedProfiles[1], "order", "desc"), // отсортированные, на основе достижений
        ...shuffle(groupedProfiles[2]), // перемешанные профили с малым весом (1-2)
        ...shuffle(groupedProfiles[3]), // перемешанные с малым весом (0)

        ...orderBy(groupedSpecials[1], "order", "desc"),
        ...shuffle(groupedSpecials[2]),
        ...shuffle(groupedSpecials[3]),
      ];

      if (this.isJunior && !this.is4GradeJunior) {
        return result.map((profile) => {
          const events = profile.steps
            ?.filter(
              (step) => step.stage === FINAL_STAGE && step.talent_event_id
            )
            .map((step) => {
              return this.events[step.talent_event_id];
            });
          const finalEvent = getJuniorFinalEvent(events, this.currentEducation);
          return {
            ...profile,
            finalEvent,
          };
        });
      }
      return result;
    },

    filteredList() {
      const { search, profilesList, selectedRoutesIds, selectedActivitiesIds } =
        this;
      let result = profilesList;
      if (search) {
        const low = search.toLowerCase();
        result = result.filter((p) => {
          return p._searchTitle.includes(low);
        });
      }

      if (selectedRoutesIds.length) {
        result = result.filter((p) => {
          return p.routes?.some((n) => selectedRoutesIds.includes(n.id));
        });
      }
      if (selectedActivitiesIds.length) {
        result = result.filter((p) => {
          return p.activities?.some((n) =>
            selectedActivitiesIds.includes(n.id)
          );
        });
      }

      return result;
    },

    profileLimit() {
      const { limit } = this;
      if (!limit) return;
      return `${limit} ${numCases(["профиль", "профиля", "профилей"], limit)}`;
    },
    routesOptions() {
      const { allProfiles } = this;

      return uniqBy(
        allProfiles.reduce((acc, value) => {
          if (value.routes) {
            return [...acc, ...value.routes];
          }
          return acc;
        }, []),
        "id"
      ).sort((a, b) => {
        return b.title > a.title ? -1 : 1;
      });
    },
    selectedRoutesIds() {
      return this.selectedRoutes.map((n) => n.id);
    },
    selectedActivitiesIds() {
      return this.selectedActivities.map(({ id }) => id);
    },
    hasFilters() {
      const { search, selectedRoutes, selectedActivities } = this;
      return !!search || selectedRoutes.length || selectedActivities.length;
    },
    activitiesOptions() {
      const { allProfiles } = this;

      return uniqBy(
        allProfiles.reduce((acc, value) => {
          if (value.activities) {
            return [...acc, ...value.activities];
          }
          return acc;
        }, []),
        "id"
      ).sort((a, b) => {
        return b.title > a.title ? -1 : 1;
      });
    },
    isProfileSelectPending() {
      return Object.values(this.pendingProfiles).some((value) => !!value);
    },
    schedule() {
      return this.$store.state.schedule;
    },
  },
  async created() {
    this.pending = true;
    this.initialSelectedProfiles = { ...this.selectedProfiles };
    const requests = [
      this.$store.dispatch("profile/getProfiles"),
      this.$store.dispatch("participant/getMyAchievements"),
    ];
    /**
     * Для джунов нужно подтянуть инфу о мероприятиях финала
     * чтобы при выборе профиля записывать мероприятие финала в заявку
     */
    if (this.isJunior && !this.is4GradeJunior) {
      requests.push(this.$store.dispatch("profile/getAllFinalEvents"));
    }
    try {
      await Promise.all(requests);
    } catch (error) {
      console.log("error", error);
    }
    this.pending = false;
  },
  methods: {
    confirmPolicyAndSelect(profile) {
      const rejected = this.$store.state.participant.profiles.find(
        (n) => n.profile_id === profile.id
      );

      if ((profile.policy_url || profile.consent_url) && !rejected) {
        this.$modal.show(
          ProfilePolicyModal,
          {
            profileId: profile.id,
            handleSelect: this.handleSelectProfile,
          },
          MODAL_DYNAMIC_DEFAULTS
        );
      } else {
        this.handleSelectProfile(profile);
      }
    },
    async handleSelectProfile(profile) {
      const { selectedProfiles } = this;

      const scheduleError = ScheduleService.checkSelectStage(
        profile.id,
        this.schedule,
        this.isJunior
      );

      if (scheduleError) {
        this.showErrorModal({
          message: scheduleError,
          title: `Ошибка`,
        });
        return;
      }

      // если профиля нет в выбранных
      if (!selectedProfiles[profile.id]) {
        if (this.isJunior && !profile.finalEvent && !this.is4GradeJunior) {
          this.showErrorModal({
            message:
              "Вы не можете принять участие в этой сфере, так как финальный этап не проводится в вашем регионе",
            title: `Регистрация на сферу <br/> &laquo;${profile.title}&raquo;`,
          });
          return;
        }
        this.$set(this.pendingProfiles, profile.id, true);

        try {
          const payload = {
            profile_id: profile.id,
            ...(profile.finalEvent && { final_event: profile.finalEvent.id }),
          };

          await this.$store.dispatch("participant/selectProfile", payload);
          window.dataLayer?.push({
            event: "ntoProfileEvent",
            eventCategory: "nto_profile",
            eventAction: "profile_add_new",
            userProfile: profile.title,
          });

          // Если участник из 8 или 9 класса,
          // нужно спросить, какой уровень сложности
          // он хочет решать
          if (this.$store.getters["participant/isHybridTrack"]) {
            this.$modal.show(
              ProfieTrackSelectModal,
              {
                profileId: profile.id,
              },
              MODAL_DYNAMIC_DEFAULTS
            );
          }
        } catch (error) {
          const msg = error.message;

          this.showErrorModal({
            message: msg,
            status: error.status,
          });
        }
        this.$set(this.pendingProfiles, profile.id, false);
      }
    },

    async handleRefuseProfile(profile) {
      const { selectedProfiles } = this;

      const scheduleError = ScheduleService.checkSelectStage(
        profile.id,
        this.schedule,
        this.isJunior
      );

      if (scheduleError) {
        this.showErrorModal({
          message: scheduleError,
          title: `Ошибка`,
        });
        return;
      }
      // если профиля нет в выбранных
      if (selectedProfiles[profile.id]) {
        this.$set(this.pendingProfiles, profile.id, true);
        try {
          await this.$store.dispatch("participant/rejectProfile", profile.id);
          window.dataLayer?.push({
            event: "ntoProfileEvent",
            eventCategory: "nto_profile",
            eventAction: "profile_remove",
            userProfile: profile.title,
          });
        } catch (error) {
          this.showErrorModal({
            message: error.message,
            status: error.status,
          });
        }
        this.$set(this.pendingProfiles, profile.id, false);
      }
    },
    dropFilters() {
      this.search = "";
      this.selectedRoutes = [];
    },
  },
};
</script>

<style lang="less" scoped>
.select-profile-btn {
  min-width: 200px;
  &__check {
    display: inline-block;
    width: 1.25em;
    height: 1.25em;
    background-color: #fff;
    vertical-align: middle;
    margin-top: -0.2em;
    margin-right: 0.65em;
    border-radius: 2px;
    box-shadow: 0px 2px 2px 0px fade(#000, 10%);
    position: relative;
    border: 1px solid @primary-color;

    &::before {
      content: "";
      border: 3px solid @success-color;
      border-right: 0;
      border-top: 0;
      width: 1.2em;
      height: 0.65em;
      display: none;
      // box-shadow: 1px 1px 0px 0px fade(#000, 30%);

      transform: rotateZ(-45deg) translate(0.1em, 0.1em);
    }
  }
  &.is-active &__check {
    border-color: #fff;
  }
  &.is-active &__check::before {
    display: block;
  }
}

.select-reset {
  padding-top: 0.2em;
  text-align: right;
}
</style>
