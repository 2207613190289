<template>
  <div class="profile-policy-modal">
    <BaseModalContent
      :dynamic="true"
      @close="$emit('close')">
      <h2 class="text-bold text-size-h2 mb-m">Политики конфиденциальности</h2>
      <div v-if="profile">
        <div v-if="profile.policy_description">
          {{ profile.policy_description }}
        </div>
        <div v-else>
          Подтверждаю согласие на обработку и передачу персональных данных
          вузу-организатору профиля
          <span class="text-bold">&laquo;{{ profile.title }}&raquo;</span>:
        </div>

        <div class="mt-m mb-l">
          <div v-if="profile.policy_url">
            <BaseCheckbox v-model="policyConfirm">
              в соответствии с
              <a
                :href="profile.policy_url"
                target="_blank"
                rel="noopener noreferrer"
                class="link"
                @click.stop
                >политикой обработки персональных данных</a
              >
            </BaseCheckbox>
          </div>
          <div v-if="profile.consent_url">
            <BaseCheckbox v-model="consentConfirm">
              в соответствии с
              <a
                :href="profile.consent_url"
                target="_blank"
                rel="noopener noreferrer"
                class="link"
                @click.stop
                >согласием на обработку данных</a
              >
            </BaseCheckbox>
          </div>
        </div>

        <div
          v-if="error"
          class="mb-m form-error">
          Для того, чтобы выбрать этот профиль, необходимо согласиться с
          политиками конфиденциальности.
        </div>

        <BaseButton
          class="mr-m"
          @click="handleContinue"
          >Продолжить</BaseButton
        >
        <BaseButton
          theme="primary-border"
          @click="$emit('close')"
          >Отмена</BaseButton
        >
      </div>
    </BaseModalContent>
  </div>
</template>

<script>
/**
 * Модалка про политики конфиденциальности профиля
 */

export default {
  name: "ProfilePolicyModal",
  props: {
    profileId: {
      type: Number,
      required: true,
    },
    handleSelect: {
      type: Function,
    },
  },
  data() {
    return {
      policyConfirm: true,
      consentConfirm: true,
    };
  },
  computed: {
    profile() {
      return this.$store.state.profile.profiles[this.profileId];
    },
    error() {
      if (!this.profile) return;
      const { policyConfirm, consentConfirm } = this;
      const requireConsent = !!this.profile.consent_url;
      const requirePolicy = !!this.profile.policy_url;

      if (requireConsent && requirePolicy) {
        return !(consentConfirm && policyConfirm);
      } else if (requireConsent) {
        return !consentConfirm;
      } else if (requirePolicy) {
        return !policyConfirm;
      }
      return false;
    },
  },
  methods: {
    handleContinue() {
      if (this.error) return;
      if (this.handleSelect) {
        this.handleSelect(this.profile);
        this.$emit("close");
      }
    },
  },
};
</script>

<style></style>
