import { render, staticRenderFns } from "./Profiles.vue?vue&type=template&id=d6a41fcc&scoped=true&"
import script from "./Profiles.vue?vue&type=script&lang=js&"
export * from "./Profiles.vue?vue&type=script&lang=js&"
import style0 from "./Profiles.vue?vue&type=style&index=0&id=d6a41fcc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6a41fcc",
  null
  
)

export default component.exports